<template>
  <div class="box">
    <div class="header">
      <div class="header-search">
        <van-nav-bar
          :title="title"
          left-text=""
          left-arrow
          @click-left="onClickLeft"
        >
        </van-nav-bar>
      </div>
    </div>
    <div class="content contentbox loadingbox" v-if="loadingfalg">
      <van-loading size="24px" vertical>加载中...</van-loading>
    </div>
    <div class="content contentbox" v-if="showage && !loadingfalg">
      <BasicTop :basicTop="basicTop" numberrows="2" />
      <Steps
        :levels="levels"
        :hierarchy="hierarchy"
        v-if="levels && appStatus == 1"
      />
      <div class="content-show">
        <van-tabs
          v-model="active"
          :animated="true"
          :class="appStatus != 1 ? '' : 'disappear'"
        >
          <van-tab title="详情页" name="a">
            <van-list :finished="true" finished-text="没有更多了">
              <van-collapse v-model="activeNames">
                <van-collapse-item title="独家公示信息" name="1" icon="label">
                  <van-cell-group class="group">
                    <van-cell title="公示标题" :value="details.onlytopic" />
                    <van-cell title="采购预案" :value="details.projtopic" />
                    <van-cell title="询价书" :value="details.enqutopic" />
                    <van-cell title="业务员" :value="details.createUser" />
                    <van-cell title="编制时间" :value="details.createdate" />
                  </van-cell-group>
                </van-collapse-item>
                <van-collapse-item
                  title="采购申请信息"
                  name="1"
                  icon="label"
                  class="unique"
                >
                  <div
                    class="supplies"
                    v-for="(item, index) in details.stockOrderVOList"
                    :key="index"
                  >
                    <div class="supplies-title">{{ item.strprodname }}</div>
                    <div class="supplies-text">
                      物资编码: {{ item.strprodcode }}
                    </div>
                    <div class="supplies-text">
                      数量: {{ item.number }}{{ item.measunit }}
                    </div>
                    <div class="supplies-text">
                      计划交货期: {{ item.needdate }}
                    </div>
                    <div class="supplies-text">
                      独家报价供应商: {{ item.suppName }}
                    </div>
                  </div>
                </van-collapse-item>
              </van-collapse>
            </van-list>
          </van-tab>
          <van-tab title="审批记录" name="c">
            <van-list :finished="true" finished-text="没有更多了">
              <div
                class="record"
                v-for="(item, index) in details.wfapproveinfoVOList"
                :key="index"
              >
                <van-cell-group>
                  <van-cell title="审批层级" :value="item.node"></van-cell>
                  <van-cell title="审批节点" :value="item.nodename"></van-cell>
                  <van-cell title="审批人" :value="item.userName"></van-cell>
                  <van-cell
                    title="审批时间"
                    :value="item.enddatetime"
                  ></van-cell>
                  <van-cell
                    title="审批结论"
                    :value="item.resultType"
                  ></van-cell>
                  <van-cell
                    title="审批意见"
                    :value="item.result"
                    v-if="item.resultType == '不同意'"
                  ></van-cell>
                </van-cell-group>
              </div>
            </van-list>
          </van-tab>
        </van-tabs>
      </div>
    </div>
    <div
      class="footer"
      v-if="appStatus == 1 && showage == true && !loadingfalg"
    >
      <div class="footer-left" @click="agree" v-if="show">
        <span><van-icon name="success" size="20"/></span>
        <span>同意</span>
      </div>
      <div class="footer-right" @click="noagree" v-if="show">
        <span><van-icon name="cross" size="20"/></span>
        <span>不同意</span>
      </div>
      <div class="footer-left" v-if="!show">
        <span><van-icon name="success" size="20"/></span>
        <span>提交中</span>
      </div>
      <div class="footer-right" v-if="!show">
        <span><van-icon name="cross" size="20"/></span>
        <span>不同意</span>
      </div>
    </div>
    <Noagree v-if="!showage" :submitflag="submitflag" />
  </div>
</template>
<script>
import { Dialog } from "vant";
import { Toast } from "vant";
export default {
  data() {
    return {
      loadingfalg: true,
      submitflag: false,
      show: true,
      showage: true,
      title: "独家采购审批详情",
      loading: false,
      finished: true,
      isLoading: false,
      active: "a",
      activeNames: ["1", "2", "3", "4", "5", "6"],
      details: [],
      levels: null,
      hierarchy: 1,
      basicTop: {
        data: [],
        columns: [
          {
            prop: "publicityType",
            label: "公示环节",
          },
          {
            prop: "onlyReason",
            label: "独家采购原因",
          },
          {
            prop: "createComp",
            label: "采购实施单位",
          },
          {
            prop: "projtopic",
            label: "采购预案",
          },
        ],
      },
    };
  },
  created() {
    this.getOnlypublicitybillDetail();
  },
  computed: {
    onlypublicitybillId() {
      return this.$route.query.id;
    },
    appStatus() {
      return this.$route.query.appStatus;
    },
  },
  methods: {
    async getOnlypublicitybillDetail() {
      const params = {
        onlypublicitybillId: this.onlypublicitybillId,
      };
      let data = await this.$api.ec.getOnlypublicitybillDetail(params);
      if (data) {
        this.loadingfalg = false;
      }
      this.details = data;
      this.basicTop.data = data;
      this.levels = Number(data.levels);
    },
    async auditApply(o, message) {
      let params;
      if (o == 2) {
        this.submitflag = true;
        params = {
          onlypublicitybillId: this.onlypublicitybillId,
          audit: 0,
          leadexam: message,
        };
      } else {
        params = {
          onlypublicitybillId: this.onlypublicitybillId,
          audit: 1,
          leadexam: "同意",
        };
      }
      let data = await this.$api.ec.auditOnlypublicitybill(params);
      if (data) {
        Toast.success("提交成功");
        setTimeout(() => {
          this.$router.push({
            name: "ecexclusive",
            query: { type: 1 },
          });
        }, 1000);
      }
    },
    onClickLeft() {
      if (this.showage) {
        this.$router.push({
          name: "ecexclusive",
        });
      } else {
        this.showage = true;
        this.title = "独家采购审批详情";
      }
    },
    onRefresh() {
      this.loading = true;
    },
    onLoad() {},
    listDetails() {},
    agree() {
      Dialog.confirm({
        title: "审批",
        message: "是否同意",
      })
        .then(() => {
          this.show = false;
          this.auditApply();
        })
        .catch(() => {
          // on cancel
        });
    },
    noagree() {
      this.title = "审批";
      this.showage = false;
    },
  },
};
</script>
<style lang="less" scoped>
.footer-left {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: #70e6ea;
}
.footer-right {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: #f77269;
}
/deep/.group {
  .van-cell {
    color: #8f8f8f;
  }
}
/deep/.van-cell {
  background-color: transparent;
}
/deep/.van-cell {
  font-size: 12px;
}
/deep/.van-collapse-item__content {
  font-size: 12px;
  padding: 0;
}
/deep/.van-cell__value {
  color: #333;
}
.record {
  margin-top: 10px;
}
</style>
